import * as React from "react";
import { APPLE_STORE_URL } from "../utils/detect-os";

export default function Apple() {
  React.useEffect(() => {
    window.location.replace(APPLE_STORE_URL);
  })
  return (
    <div>
    </div>
  );
}
